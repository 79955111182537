import styled from '@emotion/styled'
import { useEffect, useRef } from 'react'

const  LaunchTimerContainer = styled.div`
    display: flex;
    min-height: 250px;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 600px) {
        min-height: 150px;
    }
`

const LaunchTimerContent = styled.div`
    display: flex;
    align-items: center;
    max-width: 600px;
    gap: 20px;

    .colon {
        font-size: 48px;
        font-family: var(--head-text);
        color: #FFFFFF50;
    }

    @media screen and (max-width: 600px) {
        gap: 5px;

        .colon {
            font-size: 24px;
        }
    }
`

const LaunchTimeCard = styled.div`
    display: flex;
    width: 110px;
    flex-direction: column;
    justify-content: center;
    border: 2px solid rgba(255, 255, 255, 0.1);
    align-items: center;
    padding: 16px 0;
    box-sizing: border-box;
    border-radius: 12px;
    background: linear-gradient(to bottom, #FFFFFF10, #FFFFFF00);

    span{
        font-family: var(--head-text);
        font-size: 64px;
        letter-spacing: 1px;
        margin-left: 0.5px;
        color: var(--primary);
    }

    .time-labels {
        font-family: var(--head-text);
        font-size: 14px;
        letter-spacing: 5px;
        color: #FFFFFF50;
        margin-left: 2.5px;
    }

    @media screen and (max-width: 600px) {
        width: 60px;
        border-radius: 7.5px;

        span {
            font-size: 28px;
        }

        .time-labels {
            font-family: var(--head-text);
            font-size: 12px;
            letter-spacing: 1px;
            color: #FFFFFF50;
        }
    }
`


const LaunchTimer = () => {
    const daysRef = useRef<HTMLSpanElement>(null);
    const hoursRef = useRef<HTMLSpanElement>(null);
    const minutesRef = useRef<HTMLSpanElement>(null);
    const secondsRef = useRef<HTMLSpanElement>(null);

    // Set the target date in UTC to be in CET
    const countDownDate = new Date(Date.UTC(2024, 8, 16, 10, 0, 0)).getTime(); // September 16, 2024, 00:00 UTC


    useEffect(() => {
        const x = setInterval(function() {
            // Get current UTC time
            const now = new Date().getTime();
            
            // Calculate the time remaining until the countdown date (in CET)
            const distance = countDownDate - now;

            if (distance < 0) {
                clearInterval(x);
                if (daysRef.current) daysRef.current.innerHTML = "00";
                if (hoursRef.current) hoursRef.current.innerHTML = "00";
                if (minutesRef.current) minutesRef.current.innerHTML = "00";
                if (secondsRef.current) secondsRef.current.innerHTML = "00";
                return;
            }

            const days = Math.floor(distance / (1000 * 60 * 60 * 24));
            const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((distance % (1000 * 60)) / 1000);

            if (daysRef.current) daysRef.current.innerHTML = String(days).padStart(2, '0');
            if (hoursRef.current) hoursRef.current.innerHTML = String(hours).padStart(2, '0');
            if (minutesRef.current) minutesRef.current.innerHTML = String(minutes).padStart(2, '0');
            if (secondsRef.current) secondsRef.current.innerHTML = String(seconds).padStart(2, '0');

        }, 1000);

        return () => {
            clearInterval(x);
        };
    }, [countDownDate]);


  return (
    <LaunchTimerContainer>
            <LaunchTimerContent>
                <LaunchTimeCard>
                    <span ref={daysRef}>
                        00
                    </span>
                    <span className='time-labels'>
                        DAYS
                    </span>
                </LaunchTimeCard>

                <span className="colon">
                    :
                </span>

                <LaunchTimeCard>
                    <span ref={hoursRef}>
                        00
                    </span>
                    <span className='time-labels'>
                        HOURS
                    </span>
                </LaunchTimeCard>

                <span className="colon">
                    :
                </span>

                <LaunchTimeCard>
                    <span ref={minutesRef}>
                        00
                    </span>
                    <span className='time-labels'>
                        MINUTES
                    </span>
                </LaunchTimeCard>

                <span className="colon">
                    :
                </span>

                <LaunchTimeCard>
                    <span ref={secondsRef}>
                        00
                    </span>
                    <span className='time-labels'>
                        SECONDS
                    </span>
                </LaunchTimeCard>
                
            </LaunchTimerContent>
        </LaunchTimerContainer>
  )
}

export default LaunchTimer