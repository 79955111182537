import styled from "@emotion/styled"

const LaunchWeekBannerContainer = styled.div`
    min-height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
`

const LaunchWeekBannerContent = styled.div`
    font-size: 4.5em;
    font-weight: 400;
    font-family: var(--title-font), sans-serif;
    letter-spacing: 1px;
    text-align: center;
    @media screen and (max-width: 800px) {
        font-size: 3em;
    }
    
    @media screen and (max-width: 530px) {
        font-size: 2em;
    }
`
const LaunchWeekBanner = () => {
  return (
    <LaunchWeekBannerContainer>
        <LaunchWeekBannerContent>
            <span className="head-txt-primary">ROKK LAUNCH WEEK IS HERE!</span>
        </LaunchWeekBannerContent>
    </LaunchWeekBannerContainer>
  )
}

export default LaunchWeekBanner